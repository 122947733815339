import React from 'react';
import profile_pic from '../assets/images/cofc.png';
const Home = () => {
    React.useEffect(() => {
        document.title = 'hrross - Home';
    }, []);
    return (React.createElement("section", { className: 'hero is-fullheight-with-navbar' },
        React.createElement("div", { className: 'hero-body' },
            React.createElement("div", { className: 'container has-text-centered', style: { zIndex: 2 } },
                React.createElement("p", { className: 'title', style: { fontSize: 64, color: '#bbb' } },
                    "Welcome! I'm\u00A0",
                    React.createElement("b", null, "Hunter Ross")),
                React.createElement("p", { className: 'subtitle', style: { fontSize: 24, color: '#bbb' } }, "Sensilla Cyber-Security R&D Engineer")),
            React.createElement("img", { style: {
                    position: 'absolute',
                    right: 40,
                    opacity: 0.5,
                    height: 'calc(100% - 89px)'
                }, src: profile_pic }))));
};
export default Home;
