import React from 'react';
import ReactDOM from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import '../assets/scss/blog.scss';
import Maple2022Honk from '../posts/ctf_writeups/maple2022honk.mdx';
const categories = ['CTF Writeups'];
const BlogMap = {
    'CTF Writeups': {
        maple2022honk: {
            title: 'MapleCTF 2022 - honksay',
            date: '2022-08-29',
            document: React.createElement(Maple2022Honk, null)
        }
    }
};
const ToTopButton = () => {
    const [visible, setVisible] = React.useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        }
        else if (scrolled <= 300) {
            setVisible(false);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    React.useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => {
            window.removeEventListener('scroll', toggleVisible);
        };
    }, []);
    return ReactDOM.createPortal(React.createElement("div", { className: 'to-top-button', style: { display: visible ? 'block' : 'none' }, onClick: scrollToTop },
        React.createElement("i", { className: 'fas fa-arrow-up fa-2xl' })), document.getElementById('root'));
};
const Blog = () => {
    const [tab, setTab] = React.useState(categories[0]);
    const { id } = useParams();
    React.useEffect(() => {
        if (id) {
            document.title = `hrross - ${BlogMap[tab][id].title}`;
        }
        else {
            document.title = 'hrross - Blog';
        }
    }, [id]);
    if (id) {
        return (React.createElement("div", { className: 'section' },
            React.createElement("div", { className: 'container', style: { paddingBottom: 100 } },
                BlogMap[tab][id].document,
                React.createElement("hr", null),
                "Questions or comments?\u00A0Click the",
                ' ',
                React.createElement("i", { className: 'fas fa-envelope' }),
                " icon in the navbar to send me an email.",
                React.createElement(ToTopButton, null))));
    }
    else {
        return (React.createElement("div", { className: 'section' },
            React.createElement("div", { style: { width: '25%' }, className: 'container has-text-centered' },
                React.createElement("div", { className: 'panel is-primary' },
                    React.createElement("p", { className: 'panel-heading' }, "Posts"),
                    React.createElement("p", { className: 'panel-tabs' }, categories.map((category) => (React.createElement("a", { key: category, className: tab === category ? 'is-active' : '', onClick: () => setTab(category) }, category)))),
                    Object.keys(BlogMap[tab]).map((post) => (React.createElement(Link, { key: post, className: 'panel-block', to: `${post}` },
                        BlogMap[tab][post].title,
                        React.createElement("span", { style: { marginLeft: 'auto' } }, BlogMap[tab][post].date))))))));
    }
};
export default Blog;
