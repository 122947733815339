import React from 'react';
const Opensource = () => {
    React.useEffect(() => {
        document.title = 'hrross - OSS';
    }, []);
    return (React.createElement("div", { className: 'section' },
        React.createElement("div", { className: 'container has-text-centered' },
            React.createElement("h1", { className: 'title' }, "Personal Projects"),
            React.createElement("div", { className: 'columns' },
                React.createElement("div", { className: 'column is-6' },
                    React.createElement("div", { className: 'card', style: { height: '100%' } },
                        React.createElement("header", { className: 'card-header' },
                            React.createElement("p", { className: 'card-header-title is-centered' },
                                "React Column Select",
                                React.createElement("a", { style: { marginLeft: 5 }, target: '_blank', href: 'https://github.com/huner2/react-column-select' },
                                    React.createElement("i", { className: 'fab fa-github fa-xl' })),
                                React.createElement("a", { style: { marginLeft: 5 }, target: '_blank', href: 'https://www.npmjs.com/package/@huner2/react-column-select' },
                                    React.createElement("i", { className: 'fab fa-npm fa-xl' })))),
                        React.createElement("div", { className: 'card-image' },
                            React.createElement("img", { src: require('../assets/images/rcs.png'), alt: 'React-Column-Select', style: { borderRadius: 0, padding: '0 30px' } })),
                        React.createElement("div", { className: 'card-content' },
                            React.createElement("div", { className: 'content has-text-justified' },
                                "React Column Select is a package designed to allow for creation of a multi-select field in the form of two columns. Selecting elements will move them from one side to another. This package is a fork of",
                                ' ',
                                React.createElement("a", { target: '_blank', href: 'https://github.com/VovanR/react-multiselect-two-sides' }, "react-multiselect-two-sides"),
                                ' ',
                                "with a few changes.",
                                React.createElement("br", null),
                                "This package is a typescript conversion, thus types are exported and visible in bundles. Peer-dependencies have been updated to use recent versions of React. Tests have been rewritten using modern testing frameworks. The API is a little bit stricter due to the new Typescript types.")))),
                React.createElement("div", { className: 'column is-6' },
                    React.createElement("div", { className: 'card', style: { height: '100%' } },
                        React.createElement("header", { className: 'card-header' },
                            React.createElement("p", { className: 'card-header-title is-centered' },
                                "Go-WSUS",
                                React.createElement("a", { style: { marginLeft: 5 }, target: '_blank', href: 'https://github.com/huner2/go-wsus/tree/dev' },
                                    React.createElement("i", { className: 'fab fa-github fa-xl' })))),
                        React.createElement("div", { className: 'card-image' },
                            React.createElement("img", { src: require('../assets/images/wsus.png'), alt: 'Go-WSUS', style: { borderRadius: 0, padding: '0 30px' } })),
                        React.createElement("div", { className: 'card-content' },
                            React.createElement("div", { className: 'content has-text-justified' }, "A work in progress library for managing, querying, and debugging WSUS servers through the SOAP API that is exposed. The idea is to allow users to manage WSUS programatically and to provide a data source for logging, debugging, analysis, etc. It also abstracts the coercion of data into the expected XML format. The repository includes an example binary for reference to craft needed queries."))))),
            React.createElement("hr", null),
            React.createElement("h1", { className: 'title' }, "External Projects"),
            React.createElement("div", { className: 'columns' },
                React.createElement("div", { className: 'column is-6 is-offset-3' },
                    React.createElement("div", { className: 'card', style: { height: '100%' } },
                        React.createElement("header", { className: 'card-header' },
                            React.createElement("p", { className: 'card-header-title is-centered' },
                                "Definitely Typed",
                                React.createElement("a", { style: { marginLeft: 5 }, target: '_blank', href: 'https://github.com/DefinitelyTyped/DefinitelyTyped' },
                                    React.createElement("i", { className: 'fab fa-github fa-xl' })),
                                React.createElement("a", { style: { marginLeft: 5 }, target: '_blank', href: 'https://www.npmjs.com/~types' },
                                    React.createElement("i", { className: 'fab fa-npm fa-xl' })))),
                        React.createElement("div", { className: 'card-image' },
                            React.createElement("img", { src: require('../assets/images/DefinitelyTyped.png'), alt: 'React-Column-Select', style: { borderRadius: 0, padding: '0 30px' } })),
                        React.createElement("div", { className: 'card-content' },
                            React.createElement("div", { className: 'content has-text-justified' },
                                "DefinitelyTyped is a community-driven types collection for npm packages. The types can be installed to provide type definitions for packages that don't include their own type definitions.",
                                React.createElement("br", null),
                                "So far, I've contributed types for the",
                                ' ',
                                React.createElement("a", { target: '_blank', href: 'https://github.com/nowaalex/af-utils' }, "@af-util"),
                                ' ',
                                "libraries: react-table, react-virtual-headless, and react-virtual-list. These contributed types are included in the main repository."))))))));
};
export default Opensource;
