import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
export const Layout = () => {
    const [expanded, setExpanded] = React.useState(false);
    const location = useLocation();
    return (React.createElement(React.Fragment, null,
        React.createElement("nav", { className: 'navbar', role: 'navigation', "aria-label": 'main navigation' },
            React.createElement("div", { className: 'navbar-brand' },
                React.createElement("a", { role: 'button', className: `navbar-burger ${expanded ? 'is-active' : ''}`, "aria-label": 'menu', "aria-expanded": 'false', onClick: () => setExpanded(!expanded) },
                    React.createElement("span", { "aria-hidden": 'true' }),
                    React.createElement("span", { "aria-hidden": 'true' }),
                    React.createElement("span", { "aria-hidden": 'true' }))),
            React.createElement("div", { className: `navbar-menu ${expanded ? 'is-active' : ''}` },
                React.createElement("div", { className: 'navbar-start', style: { flexGrow: 1, justifyContent: 'center' } },
                    React.createElement(NavLink, { className: `navbar-item ${location.pathname === '/' ? 'is-active' : ''}`, to: '/' }, "Home"),
                    React.createElement(NavLink, { className: `navbar-item ${location.pathname === '/about' ? 'is-active' : ''}`, to: '/about' }, "About"),
                    React.createElement(NavLink, { className: `navbar-item ${location.pathname === '/oss' ? 'is-active' : ''}`, to: '/oss' }, "Open-Source-Contributions"),
                    React.createElement(NavLink, { className: `navbar-item ${location.pathname === '/blog' ? 'is-active' : ''}`, to: '/blog' }, "Blog")),
                React.createElement("div", { className: 'buttons', style: { position: 'absolute', right: 0 } },
                    React.createElement("a", { target: '_blank', className: 'navbar-item fab fa-github', href: 'https://github.com/huner2' }),
                    React.createElement("a", { className: 'navbar-item fas fa-envelope', target: '_blank', href: 'mailto:hrross@hrross.net?subject=Be%20sure%20to%20check%20your%20spam%20if%20you%27re%20expecting%20a%20response%20-%20Replace%20with%20your%20subject' }),
                    React.createElement("a", { className: 'navbar-item', href: 'https://sensilla.cloud' },
                        React.createElement("img", { src: require('../assets/images/Icon.png'), alt: 'Sensilla', style: { margin: 0 }, id: 'sensilla' }))))),
        React.createElement(Outlet, null)));
};
export default Layout;
