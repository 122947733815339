import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './assets/scss/index.scss';
import Layout from './containers/Layout';
import Home from './containers/Home';
import About from './containers/About';
import Opensource from './containers/Opensource';
import Blog from './containers/Blog';
const root = createRoot(document.getElementById('root'));
root.render(React.createElement(BrowserRouter, null,
    React.createElement(Routes, null,
        React.createElement(Route, { element: React.createElement(Layout, null) },
            React.createElement(Route, { path: '/', element: React.createElement(Home, null) }),
            React.createElement(Route, { path: '/about', element: React.createElement(About, null) }),
            React.createElement(Route, { path: '/oss', element: React.createElement(Opensource, null) }),
            React.createElement(Route, { path: '/blog', element: React.createElement(Blog, null) }),
            React.createElement(Route, { path: '/blog/:id', element: React.createElement(Blog, null) }),
            React.createElement(Route, { path: '*', element: React.createElement(Home, null) })))));
