import React from 'react';
import profile_pic from '../assets/images/cofc.png';
import acs_paper from '../assets/images/ACS Paper.pdf';
import networking_paper from '../assets/images/paper.pdf';
const About = () => {
    React.useEffect(() => {
        document.title = 'hrross - About';
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'section' },
            React.createElement("div", { className: 'container has-text-centered' },
                React.createElement("p", { className: 'title' }, "Who Am I?"),
                React.createElement("div", { className: 'level', style: { alignItems: 'flex-start' } },
                    React.createElement("div", { className: 'level-left' },
                        React.createElement("img", { src: profile_pic, alt: 'Profile', style: { height: 400, borderRadius: '1em' }, id: 'profile-pic' })),
                    React.createElement("div", { className: 'level-item', style: { flexShrink: 1 } },
                        React.createElement("p", { className: 'has-text-justified is-size-5', style: { marginLeft: 20 } },
                            "I was born and raised in Charleston, SC. I attended Wando High School and was a member/leader of the club throughout my high school years.",
                            React.createElement("br", null),
                            "I then attended Clemson University and graduated with a B.S. in Computer Science in 2020.",
                            React.createElement("br", null),
                            "I then followed up my B.S. with a M.S. in Computer Science at the College of Charleston and graduated in 2021.",
                            React.createElement("br", null),
                            "I've been working for Sensilla Inc. since I graduated high school in 2017.",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            "In my free time, I enjoy playing video games, singing, and picking up a few instruments.",
                            React.createElement("br", null),
                            "I enjoy working on little projects here in there (like woodworking) to learn new things.",
                            React.createElement("br", null),
                            "I also enjoy competing in CTF competitions and working on open-source software.",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            "I now live in the Austin, TX area. I plan on moving to the south-east coast of Florida within the next couple years."))),
                React.createElement("hr", null),
                React.createElement("div", { style: { position: 'relative' } },
                    React.createElement("p", { className: 'title' }, "Timeline"),
                    React.createElement("span", { className: 'timeline-line' }),
                    React.createElement("div", { className: 'timeline-card' },
                        React.createElement("div", { className: 'timeline-card-content' },
                            React.createElement("div", { className: 'timeline-card-title is-size-3' },
                                React.createElement("h3", null, "May 2017")),
                            React.createElement("div", { className: 'timeline-card-text is-size-5 has-text-justified' },
                                React.createElement("p", null,
                                    "Graduated High School, and began working for Sensilla as a contractor.",
                                    React.createElement("br", null),
                                    "My first summer I was sub-contracted to CyberAdapt; I was reverse-engineering Android malware in-order to provide network traffic signatures.")))),
                    React.createElement("div", { className: 'timeline-card' },
                        React.createElement("div", { className: 'timeline-card-content' },
                            React.createElement("div", { className: 'timeline-card-title is-size-3' },
                                React.createElement("h3", null, "August 2017 - May 2020")),
                            React.createElement("div", { className: 'timeline-card-text is-size-5 has-text-justified' },
                                React.createElement("p", null,
                                    "Began my education at Clemson University persuing a B.S. in Computer Science.",
                                    React.createElement("br", null),
                                    "During my time at Clemson University, I was a member of the Clemson cyber-security club. I participated in multiple CTFs as well as other competitions with members of the club.",
                                    React.createElement("br", null),
                                    "I was also a undergraduate lab assistant for the C, C++, and ARM assembly classes.",
                                    React.createElement("br", null),
                                    "I graduated Clemson successfully with a B.S. in Computer Science.")))),
                    React.createElement("div", { className: 'timeline-card' },
                        React.createElement("div", { className: 'timeline-card-content' },
                            React.createElement("div", { className: 'timeline-card-title is-size-3' },
                                React.createElement("h3", null, "August 2020 - December 2021")),
                            React.createElement("div", { className: 'timeline-card-text is-size-5 has-text-justified' },
                                React.createElement("div", { className: 'content' },
                                    "Began my graduate education at the College of Charleston persuing a M.S. in Computer Science.",
                                    React.createElement("br", null),
                                    "After my acceptance to the college, I was",
                                    ' ',
                                    React.createElement("a", { target: '_blank', href: 'https://twitter.com/csatcofc/status/1293579823928025090' }, "awarded the prestigious Amy Thompson McCandless Fellowship."),
                                    React.createElement("br", null),
                                    "During my time at the College of Charleston, I was a graduate lab instructor for the intro programming class (taught in Python 3).",
                                    React.createElement("br", null),
                                    "I also wrote multiple research papers for my graduate classes:",
                                    React.createElement("dl", null,
                                        React.createElement("dt", null,
                                            React.createElement("b", null, "An Analysis of Signature-Based Detection of Session-Based DNS Command and Control")),
                                        React.createElement("dd", null,
                                            React.createElement("a", { target: '_blank', href: acs_paper }, "Link")),
                                        React.createElement("dt", null,
                                            React.createElement("b", null, "An Information Sharing Protocol for Metropolitan Vehicles")),
                                        React.createElement("dd", null,
                                            React.createElement("a", { target: '_blank', href: networking_paper }, "Link"))),
                                    React.createElement("br", null),
                                    "I graduated the College of Charleston successfully with full honors and a M.S. in Computer Science.")))),
                    React.createElement("div", { className: 'timeline-card' },
                        React.createElement("div", { className: 'timeline-card-content' },
                            React.createElement("div", { className: 'timeline-card-title is-size-3' },
                                React.createElement("h3", null, "January 2022 - Present")),
                            React.createElement("div", { className: 'timeline-card-text is-size-5 has-text-justified' },
                                React.createElement("p", null,
                                    "Transitioned to a full time employee of Sensilla Inc.",
                                    React.createElement("br", null),
                                    "Currently working as a cyber-security R&D engineer working on development of the Sensilla IDS and the corresponding services."))))),
                React.createElement("hr", null),
                React.createElement("p", { className: 'title' }, "Proficient Technologies"),
                React.createElement("div", { className: 'columns is-multline', id: 'skills' },
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://go.dev' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/golang/golang-ar21.svg', alt: 'Golang' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://www.lua.org' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/lua/lua-icon.svg', alt: 'Lua' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://www.python.org' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/python/python-vertical.svg', alt: 'Python' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://cplusplus.com' },
                            React.createElement("img", { src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg', alt: 'C/C++' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://aws.amazon.com' },
                            React.createElement("img", { src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original-wordmark.svg', alt: 'AWS' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://www.docker.com' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/docker/docker-official.svg', alt: 'Docker' })))),
                React.createElement("div", { className: 'columns is-multline', id: 'skills' },
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://sass-lang.com' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/sass-lang/sass-lang-ar21.svg', alt: 'Sass' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://www.postgresql.org' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/postgresql/postgresql-vertical.svg', alt: 'PostgreSQL' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://www.postman.com' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg', alt: 'Postman' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://www.typescriptlang.org' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/typescriptlang/typescriptlang-icon.svg', alt: 'TypeScript' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://docs.microsoft.com/en-us/dotnet/csharp/' },
                            React.createElement("img", { src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg', alt: 'C#' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://about.gitlab.com' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/gitlab/gitlab-icon.svg', alt: 'GitLab' })))),
                React.createElement("div", { className: 'columns is-multline', id: 'skills' },
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://www.java.com/en/' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/java/java-vertical.svg', alt: 'Java' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://reactjs.org' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/reactjs/reactjs-icon.svg', alt: 'React' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://www.linux.org' },
                            React.createElement("img", { src: 'https://www.vectorlogo.zone/logos/linux/linux-icon.svg', alt: 'Linux' }))),
                    React.createElement("div", { className: 'column' },
                        React.createElement("a", { target: '_blank', href: 'https://bulma.io' },
                            React.createElement("img", { src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bulma/bulma-plain.svg', alt: 'Bulma' }))))))));
};
export default About;
